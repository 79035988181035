import crudMutations from "@/store/templates/crud/mutations";

export default {
  setName(state, name) {
    state.model.name = name;
  },
  setColor(state, color) {
    state.model.color = color;
  },
  ...crudMutations
};
